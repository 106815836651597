* {
  box-sizing: border-box;
}
.headerSection {
  position: relative;
  background-color: rgb(32, 10, 10);
  color: white;

  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.headerSection .headerLogo .logoLink {
  text-decoration: none;
}
.headerSection .headerLogo h1 {
  padding: 0 20px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  letter-spacing: -2px;
  transform: scale(0.7, 1.6);
  background-color: red;
  color: white;
}
.navigation {
  width: 70%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.headerNavigation {
  width: 70%;
  display: flex;
  justify-content: space-evenly;
  list-style-type: none;
}
.headerNavigation li {
  padding: 1rem;
  border-radius: 5px;
  transition: 0.2s;
}
.headerNavigation li:hover {
  background-color: rgb(46, 46, 46);
}
.headerNavigation li .genreLink {
  text-decoration: none;
  color: white;
}
.hamburgerMenu {
  display: none;
}

@media screen and (max-width: 880px) {
  .headerSection {
    justify-content: space-between;
    padding: 0 20px;
  }
  .hamburgerMenu {
    display: block;
    justify-self: flex-end;
  }
  .navigation {
    width: 100%;
    background-color: rgb(32, 10, 10);
    flex-direction: column;
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 5;
    padding-bottom: 10px;
  }
  .navigation .headerNavigation {
    width: 100%;
    flex-direction: column;
    align-items: center;
    padding: 0;
  }
  .headerNavigation li {
    width: 100%;
    text-align: center;
    border: 1px solid rgb(129, 101, 101);
  }
  .navigationOff {
    display: none;
  }
  label {
    display: flex;
    flex-direction: column;
    width: 70px;
    cursor: pointer;
  }

  label span {
    background: #fff;
    border-radius: 10px;
    height: 6px;
    margin: 7px 0;
    transition: 0.4s cubic-bezier(0.68, -0.6, 0.32, 1.6);
  }

  span:nth-of-type(1) {
    width: 50%;
  }

  span:nth-of-type(2) {
    width: 100%;
  }

  span:nth-of-type(3) {
    width: 75%;
  }

  input[type="checkbox"] {
    display: none;
  }

  input[type="checkbox"]:checked ~ span:nth-of-type(1) {
    transform-origin: bottom;
    transform: rotatez(45deg) translate(8px, 0px);
  }

  input[type="checkbox"]:checked ~ span:nth-of-type(2) {
    transform-origin: top;
    transform: rotatez(-45deg);
  }

  input[type="checkbox"]:checked ~ span:nth-of-type(3) {
    transform-origin: bottom;
    width: 50%;
    transform: translate(30px, -11px) rotatez(45deg);
  }
}
