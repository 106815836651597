.characterPageSection {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.characterPageSection .thumbnail {
  width: 100%;
  margin: auto;
  padding: 5% 0;
  background-color: #1e1e1e;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.characterPageSection .thumbnail img {
  width: 30%;
}
.characterPageSection .description {
  width: 90%;
  margin: auto;
  margin-top: 10px;
  padding: 10px;
  border: 4px solid black;
}
.comicsSection {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  flex-wrap: wrap;
  gap: 10px;
}

.comicsSection div {
  max-width: 500px;
  border: #1e1e1e 5px solid;
}
.comicsSection div img {
  width: 100%;
}
.comicsSection h1,
h2 {
  padding: 0 10px;
}
.comicsSection h2 {
  transition: 0.1s;
}
.comicsSection h2:hover {
  transform: scale(1.2);
  transform: translate(10px, 0);
  cursor: pointer;
  color: rgb(36, 36, 186);
}
